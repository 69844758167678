$.fn.blockFormSubmit = function() {
    $(this).on("submit", function (event) {
        form = $(this);
        event.preventDefault();
        var post_url = $(this).attr("action");
        var request_method = $(this).attr("method");
        var form_data = new FormData(this);
        $.ajax({
            url: post_url,
            type: request_method,
            data: form_data,
            contentType: false,
            cache: false,
            processData: false
        }).done(function (response) { //
            if (response.status == "ok") {
                // $(form).closest('.wcm-block').html(response.html).promise().done(function(){
                //     $('form[in-block="in-block"]').blockFormSubmit();
                // });
                if ($(form).attr('data-success') !== undefined) {
                    var fn = eval($(form).attr('data-success'));
                    blockId = $(form).attr('data-block-id');
                    if (blockId !== undefined) {
                        fn(response, blockId);
                    } else {
                        fn(response);
                    }
                }
                modal = $(form).closest('.modal');
                if (modal.length != 0) {
                    if (response.html != '') {
                        $(modal).find('.modal-body').html(response.html);
		    }
                    $(modal).modal('hide');
                    title = Translator.trans("title.send_success");
                    message = Translator.trans("message.form_send_success");
                    $.alert({
                        title: "Enviado",
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'success',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-primary'
                            }
                        }
                    });
                } else {
                    $(form).closest('.wcm-block').html(response.html);
                }
                $('form[in-block="in-block"]').off("submit");
                $('form[in-block="in-block"]').blockFormSubmit();

                if ($(form).attr('data-payment') == 'subscribe') {
                    if (response.url !== undefined) {
                        data = response.data;
                        const dataParams = Object.keys(data).map((key) => {
                            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                        }).join('&');

                        $.ajax({
                            url: response.url,
                            type: response.method,
                            data: dataParams,
                            contentType: 'application/x-www-form-urlencoded',
                            cache: false,
                            processData: false,
                            headers: {
                                Authorization: 'Bearer ' + response.data.key
                            },
                        }).done(function (response) { //
                            console.log(response);
                            response['amount'] = data['amount'];
                            response['currency'] = data['currency'];
                            response['block_id'] = parseInt($(form).closest('[data-wcm-page]').attr('id').replace('wcm-block-',''));
                            dataSubscribe = JSON.stringify(response);
                            $.ajax({
                                url: Routing.generate('frontend.payment.subscribe'),
                                type: 'POST',
                                data: dataSubscribe,
                                contentType: false,
                                cache: false,
                                processData: false,
                            }).done(function (response) { //
                                console.log(response);
                                if (response.status == "ok") {
                                    $(form).closest('.wcm-block').html(Translator.trans("message.label_operation_successful"));
                                }
                            });
                        });
                    }
                }

                if (response.msgError !== undefined) {
                    $.alert({
                        title: "Error",
                        content: response.msgError,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                }

            }
        }).fail(function (jqXHR) { //
            response = jqXHR.responseJSON;
            if (response.status == "ko") {
                // $(form).closest('.wcm-block').html(response.html).promise().done(function(){
                //     $('form[in-block="in-block"]').blockFormSubmit();
                // });
                if ($(form).attr('data-fail') !== undefined) {
                    var fn = eval($(form).attr('data-fail'));
                    fn(response);
                }
                modal = $(form).closest('.modal');
                if (modal.length != 0) {
                    $(modal).modal('hide');
                    title = Translator.trans("title.send_failed");
                    message = Translator.trans("message.form_send_failed");
                    // $.growl.error({ title: title, message: message });
                    $.alert({
                        title: "Error",
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                } else {
                    $(form).closest('.wcm-block').html(response.html);
                }


                $('form[in-block="in-block"]').blockFormSubmit();
            }
            $(form).blockFormSubmit();
        });
    });
};
